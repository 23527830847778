export let apiController = new AbortController();

export const resetAbortController = () => {
    apiController = new AbortController();;
}

import security from './security';
import { configureStore } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { getAccessTokenSilently, getLoginWithRedirect } = security;
import { DeleteDocumentParams, FetchLibraryParams, LibraryRecord, LibraryResponse, UpdateDocumentParams, } from './types/library';
import { ContentDownloadResponse, ContentTypesResponse } from './types/content';
import { AddContentToCollectionParams, AddContentToCollectionResponse, ContentSource, GetCollectionSourcesParams, GetDocumentSourcesParams, RemoveSourceFromDocumentParams } from './types/collection';
import { AgentBatchResponse, AgentCallIdsRequest, AgentPipeLineRequest as AgentPipeLineRequest, AgentResponse, AgentResponseData, AsyncAgentResponse } from './types/agent';
import { ErrorMiddleware } from './ErrorMiddleware';
import { UnAuthMiddleware } from './UnAuthMiddleware';
import { UserbackMiddleware } from './UserBackMiddleware';
import {
    CreateInvitationsRequest,
    CreateInvitationsResponse,
    Organization,
    UpdateOrganizationRequest,
    UpdateUserRolesRequest,
    UserInfo,
    OrganizationInvitation,
    OrganizationMembersResponse,
    OrganizationRolesResponse,
} from './types/organization';
import { FetchPrimaryAssetParams, PrimaryAssetResponse, FullNodeResponse, VersionResponse, NodeResponse, ContentResponse, FetchFullNodeParams, FetchVersionParams, FetchNodeParams, FetchContentParams, CreateDocumentResponse } from './types/node';
import { CodebaseUploadResponse, CodebaseVersionRequest, CodebaseVersionsResponse } from './types/codebase';
import { Charge, UsageBalance, UsageSummary } from './types/usage';
import { CodebaseReport } from './types/codebase';
import { GitRepo } from './types/github';
import { CreateGitProviderApp, GitProviderApp, GitProviderAppInstall } from './types/gitProvider';
import { Subscription } from './types/subscription';
import { AssociatedSourcesResponse } from './types/documentSources';

export const baseUrl = process.env.NEXT_PUBLIC_API_URL || process.env.NEXT_PUBLIC_API_AUDIENCE;
export const primaryAssetPath = "/node/primary_assets";
export const exportRSTPath = "/content/export-rst";

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
        try {
            const token = await getAccessTokenSilently()();
            if (!token) {
                throw new Error('No authentication token available');
            }
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json');
            return headers;
        } catch (error: any) {
            getLoginWithRedirect()({
                appState: { targetUrl: window.location.href }
            });
            throw new Error('Authentication required');
        }
    },
});

export const driverApi = createApi({
    reducerPath: 'driverApi',
    baseQuery: baseQuery,
    tagTypes: [
        "Tags",
        "CodebaseVersions",
        "Library",
        "Content",
        "ContentTags",
        "ContentTypes",
        "DocumentSources",
        "Collections",
        "CobebaseRoot",
        "CollectionSources",
        "TagContents",
        "PDF",
        "OrganizationUsers",
        "Organizations",
        "OrganizationInvitations",
        "UserInfo",
        "UsageBalance",
        "UsageSummary",
        "Charge",
        "Subscription",
        "GitRepo"
    ],
    endpoints: (builder) => ({
        getPrimaryAsset: builder.query<PrimaryAssetResponse, FetchPrimaryAssetParams>({
            query: (params) => {
                return {
                    url: primaryAssetPath,
                    params,
                }
            },
            providesTags: ["Library"]
        }),
        getFullNode: builder.query<FullNodeResponse, FetchFullNodeParams>({
            query: (params) => {
                return {
                    url: "/node/full_nodes/",
                    params,
                }
            },
            providesTags: ["Library"]
        }),
        getVersion: builder.query<VersionResponse, FetchVersionParams>({
            query: (params) => {
                return {
                    url: "/node/versions/",
                    params,
                }
            },
            providesTags: ["Library"]
        }),
        getNode: builder.query<NodeResponse, FetchNodeParams>({
            query: (params) => {
                return {
                    url: "/node/nodes/",
                    params,
                }
            },
            providesTags: ["Library"]
        }),
        getContent: builder.query<ContentResponse, FetchContentParams>({
            query: (params) => {
                return {
                    url: "/node/contents",
                    params,
                }
            },
            providesTags: ["Library"]
        }),
        getContentTags: builder.query<ContentTagsResponse, { contentId: string }>({
            query: ({ contentId }) => {
                return {
                    url: `/node/tags?primary_assets.versions.root_node.id=${contentId}`
                }
            },
            providesTags: ["ContentTags"]
        }),
        getUsageBalance: builder.query<UsageBalance, void>({
            query: () => {
                return {
                    url: `/usage/balance`,
                }
            },
            providesTags: ["UsageBalance"]
        }),
        getUsageSummary: builder.query<UsageSummary, { startDate?: string, endDate?: string }>({
            query: ({ startDate, endDate }) => {
                return {
                    url: `/usage/summary`,
                    params: {
                        start_date: startDate,
                        end_date: endDate,
                    },
                }
            },
            providesTags: ["UsageSummary"]
        }),
        getCharges: builder.query<Charge[], { limit?: number, offset?: number }>({
            query: ({ limit = 50, offset = 0 }) => {
                return {
                    url: `/usage/charges`,
                    params: {
                        limit,
                        offset,
                    },
                }
            },
            providesTags: ["Charge"]
        }),
        getContentDownloadUrl: builder.query<ContentDownloadResponse, { contentId: string }>({
            query: ({ contentId }) => {
                return {
                    url: `/content/${contentId}/download`,
                }
            },
            keepUnusedDataFor: 600,
            providesTags: ["PDF"]
        }),
        updateContent: builder.mutation<LibraryRecord, UpdateDocumentParams>({
            query(params) {
                return {
                    url: `/node/edit_page/${params.contentId}`,
                    method: 'PUT',
                    body: {
                        content_name: params.name,
                        content: params.content,
                    },
                }
            },
            invalidatesTags: ["Content", "Library"]
        }),
        createDocument: builder.mutation<CreateDocumentResponse, void>({
            query: () => {
                return {
                    url: "/node/new_page",
                    method: "POST",
                }
            },
            invalidatesTags: ["Library"]
        }),
        generateCodebases: builder.mutation<void, { version_ids: string[] }>({
            query: (body) => {
                return {
                    url: "/codebases/generate",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: ["Library"]
        }),
        createTemplate: builder.mutation<CreateDocumentResponse, void>({
            query: () => {
                return {
                    url: "/node/new_template",
                    method: "POST",
                }
            },
            invalidatesTags: ["Library"]
        }),
        deleteDocument: builder.mutation<void, DeleteDocumentParams>({
            query: (params) => {
                return {
                    url: `/node/primary_assets/${params.id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ["Library"]
        }),
        getContentByNodeId: builder.query<ContentResponse, { node_id: string, content_kind?: string, version_id?: string }>({
            query: (params) => {
                return {
                    url: `/node/contents`,
                    params,
                }
            },
        }),
        getTags: builder.query<TagGetResponse, TagGetParams>({
            query: (params) => {
                const newParams = { ...params };
                if (params.name === "") {
                    delete newParams.name;
                }
                return {
                    url: "/node/tags",
                    params: newParams,
                }
            },
            providesTags: ["Tags"]
        }),
        getTagContents: builder.query<PrimaryAssetResponse, { tagId: string }>({
            query: ({ tagId }) => {
                return {
                    url: `/node/primary_assets?tags.id=${tagId}`,
                }
            },
            providesTags: ["TagContents"]
        }),
        getContentTypes: builder.query<ContentTypesResponse, void>({
            query: () => "/content/types",
            providesTags: ["ContentTypes"]
        }),
        postCallIdResults: builder.mutation<AgentBatchResponse, AgentCallIdsRequest>({
            query: (body) => {
                return {
                    url: `/agent_pipelines/async/batch`,
                    method: "POST",
                    body,
                }
            },
        }),
        postAgentPipelineAsync: builder.mutation<AsyncAgentResponse, AgentPipeLineRequest>({
            query: (body) => {
                return {
                    url: `/agent_pipelines/async`,
                    method: "POST",
                    body,
                }
            },
        }),
        postAgentPipelineSync: builder.mutation<AgentResponseData, AgentPipeLineRequest>({
            query: (body) => {
                return {
                    url: `/agent_pipelines/sync`,
                    method: "POST",
                    body,
                    signal: apiController.signal
                }
            },
        }),
        getAgentCallStatus: builder.query<AsyncAgentResponse, { callId: string }>({
            query: ({ callId }) => `/agent_pipelines/async/${callId}/`,
        }),
        createTag: builder.mutation<TagResult, Tag>({
            query(body) {
                return {
                    url: `/tags/`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ["Tags", "Library"],
        }),
        addContentToCollection: builder.mutation<AddContentToCollectionResponse, AddContentToCollectionParams>({
            query(params) {
                return {
                    url: `/node/primary_asset_tags`,
                    method: 'POST',
                    body: {
                        primary_asset_id: params.primaryAssetId,
                        tag_id: params.tagId,
                    },
                }
            },
            invalidatesTags: ["DocumentSources", "TagContents"]
        }),
        // @TODO this isnt implemented yet
        removeContentFromCollection: builder.mutation<AddContentToCollectionResponse, AddContentToCollectionParams>({
            query(params) {
                return {
                    url: `/node/primary_asset_tags/${params.primaryAssetId}/${params.tagId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ["DocumentSources", "TagContents"]
        }),
        addSourceToDocument: builder.mutation<void, ContentSource[]>({
            query(params) {
                return {
                    url: `/node/document_sources/batch`,
                    method: 'POST',
                    body: params
                }
            },
            invalidatesTags: ["DocumentSources"]
        }),
        removeSourceFromDocument: builder.mutation<void, ContentSource[]>({
            query(params) {
                return {
                    url: `/node/document_sources/batch`,
                    method: 'DELETE',
                    body: params
                }
            },
            invalidatesTags: ["DocumentSources"]
        }),
        getDocumentSources: builder.query<AssociatedSourcesResponse, GetDocumentSourcesParams>({
            query: ({ pageNodeId }) => `/node/document_sources?page_node_id=${pageNodeId}&limit=100`,
            providesTags: ["DocumentSources"]
        }),
        addTagToContent: builder.mutation<void, AssociateTagWithContentParams>({
            query(body) {
                return {
                    url: `/node/primary_asset_tags`,
                    method: 'POST',
                    body: {
                        primary_asset_id: body.primaryAssetId,
                        tag_id: body.tagId,
                    }
                }
            },
            invalidatesTags: ["Library", "ContentTags"]
        }),
        updateTag: builder.mutation<void, UpdateTagParams>({
            query(params) {
                return {
                    url: `/tags/${params.tagId}`,
                    method: 'PUT',
                    body: params.tag,
                }
            },
            invalidatesTags: ["Tags", "Library", "ContentTags"]
        }),
        deleteTag: builder.mutation<void, DeleteTagParams>({
            query(params) {
                return {
                    url: `/tags/${params.tagId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ["Tags", "Library", "ContentTags"]
        }),
        removeTagFromContent: builder.mutation<void, AssociateTagWithContentParams>({
            query(body) {
                return {
                    url: `/node/primary_asset_tags/${body.primaryAssetId}/${body.tagId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ["Library", "ContentTags"]
        }),
        uploadCodebase: builder.mutation<CodebaseUploadResponse, { file_path: string }>({
            query: ({ file_path }) => {
                return {
                    url: "/upload/codebase",
                    method: "POST",
                    body: { file_path },
                }
            },
            invalidatesTags: ["Library"]
        }),
        connectGitlabRepos: builder.mutation<{ repos: GitRepo[] }, void>({
            query: (body) => {
                return {
                    url: "/git-provider/gitlab/connect-repos", // is this the right url?
                    method: "POST",
                    body
                }
            },
            // invalidatesTags: ["Library"]
        }),
        uploadPdf: builder.mutation<LibraryRecord, { file_path: string }>({
            query: ({ file_path }) => {
                return {
                    url: "/upload/pdf",
                    method: "POST",
                    body: { file_path },
                }
            },
            invalidatesTags: ["Library"]
        }),
        getCodebaseVersions: builder.query<CodebaseVersionsResponse, CodebaseVersionRequest>({
            query: ({ codebaseId, limit = 10, offset = 0 }) => `/codebases/${codebaseId}/versions?limit=${limit}&offset=${offset}`,
            providesTags: ['CodebaseVersions']
        }),
        getCodebaseReport: builder.query<CodebaseReport, { callId: string }>({
            query: ({ callId }) => `/codebases/analysis/${callId}`,
        }),
        onboardCodebase: builder.mutation<void, { codebase_object_key: string, call_id: string }>({
            query: ({ codebase_object_key, call_id }) => {
                return {
                    url: "/codebases/onboard",
                    method: "POST",
                    body: { codebase_object_key, call_id },
                }
            },
        }),
        analyzeCodebase: builder.mutation<{ call_id: string, codebase_object_key: string }, { download_url: string }>({
            query: ({ download_url }) => {
                return {
                    url: "/codebases/analysis",
                    method: "POST",
                    body: { download_url },
                }
            },
        }),
        getOrganizationUsers: builder.query<OrganizationMembersResponse, void>({
            query: () => '/organization/users',
            providesTags: ['OrganizationUsers']
        }),

        createOrganizationInvitations: builder.mutation<CreateInvitationsResponse, CreateInvitationsRequest>({
            query: (body) => ({
                url: '/organization/invitations',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['OrganizationInvitations']
        }),

        createGitRepo: builder.mutation<{ download_url: string }, GitRepo>({
            query: (body) => ({
                url: '/git-provider/github/clone-repo',
                method: 'POST',
                body,
            }),
        }),
        createGitProviderApp: builder.mutation<GitProviderApp, CreateGitProviderApp>({
            query: (body) => ({
                url: `/git-provider/app`,
                method: 'POST',
                body,
            }),
        }),
        createGitProviderAppRepo: builder.mutation<{ download_url: string }, { app_id: string, repo: GitRepo }>({
            query: ({ app_id, repo }) => ({
                url: `/git-provider/app/${app_id}/clone-repo`,
                method: 'POST',
                body: repo,
            }),
        }),
        connectGitProviderAppRepos: builder.mutation<{ message: string }, { app_id: string, repos: GitRepo[] }>({
            query: ({ app_id, repos }) => ({
                url: `/git-provider/app/${app_id}/connect-repos`,
                method: 'POST',
                body: repos,
            }),
        }),
        saveGitProviderAccessToken: builder.mutation<{ message: string }, { app_id: string, name: string, token: string }>({
            query: ({ app_id, name, token }) => ({
                url: `/git-provider/app/${app_id}/token`,
                method: 'POST',
                body: { name, token },
            }),
        }),
        getUserOrganizations: builder.query<Organization[], void>({
            query: () => '/user/organizations',
            providesTags: ['Organizations']
        }),

        getOrganizationInvitations: builder.query<OrganizationInvitation[], void>({
            query: () => '/organization/invitations',
            providesTags: ['OrganizationInvitations']
        }),

        removeUserFromOrganization: builder.mutation<{ message: string }, string>({
            query: (uid) => ({
                url: `/organization/users/${uid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['OrganizationUsers']
        }),

        deleteInvitation: builder.mutation<{ message: string }, string>({
            query: (invitationId) => ({
                url: `/organization/invitations/${invitationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['OrganizationInvitations']
        }),
        deleteGitProviderApp: builder.mutation<{ message: string }, { app_id: string }>({
            query: ({ app_id }) => ({
                url: `/git-provider/app/${app_id}`,
                method: 'DELETE',
            }),
        }),  
        deleteGitProviderAppInstall: builder.mutation<{ message: string }, { app_id: string, install_id: string }>({
            query: ({ app_id, install_id }) => ({
                url: `/git-provider/app/${app_id}/installations/${install_id}`,
                method: 'DELETE',
            }),
        }),    
        updateOrganization: builder.mutation<Organization, UpdateOrganizationRequest>({
            query: (body) => ({
                url: '/organization',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Organizations']
        }),

        updateUserRoles: builder.mutation<void, { uid: string, roles: UpdateUserRolesRequest }>({
            query: ({ uid, roles }) => ({
                url: `/organization/users/${uid}/roles`,
                method: 'PUT',
                body: roles,
            }),
            invalidatesTags: ['OrganizationUsers']
        }),
        resetPassword: builder.mutation<{ message: string }, void>({
            query: () => ({
                url: '/user/password',
                method: 'PUT'
            })
        }),
        getUserInfo: builder.query<UserInfo, void>({
            query: () => ({
                url: `${process.env.NEXT_PUBLIC_AUTH_URL}/userinfo`,
            }),
            providesTags: ['UserInfo']
        }),
        getSubscription: builder.query<Subscription, void>({
            query: () => ({
                url: `/subscription`,
            }),
            providesTags: ['Subscription']
        }),
        getOrganizationRoles: builder.query<OrganizationRolesResponse, { page?: number; per_page?: number }>({
            query: (params) => ({
                url: '/organization/roles',
                params: {
                    page: params.page ?? 0,
                    per_page: params.per_page ?? 100,
                },
            }),
        }),
        getGitProviderApps: builder.query<GitProviderApp[], void>({
            query: () => ({
                url: '/git-provider/app',
            }),
        }),
        getGitProviderAppInstalls: builder.query<GitProviderAppInstall[], { app_id: string }>({
            query: ({ app_id }) => ({
                url: `/git-provider/app/${app_id}/installations`,
            }),
        }),
        getGitlabLoginUrl: builder.query<{ authorize_url: string }, { app_id: string }>({
            query: ({ app_id }) => ({
                url: `/git-provider/app/${app_id}/authorize`,
            }),

        }),
        getGitProviderAppRepos: builder.query<GitRepo[], { app_id: string }>({
            query: ({ app_id }) => ({
                url: `/git-provider/app/${app_id}/repos`,
            }),

        }),

    }),
})


export const apiStore = configureStore({
    reducer: {
        [driverApi.reducerPath]: driverApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(driverApi.middleware)
        .concat(ErrorMiddleware)
        .concat(UnAuthMiddleware)
        .concat(UserbackMiddleware),
})

export const {
    useGetPrimaryAssetQuery,
    useLazyGetPrimaryAssetQuery,
    useGetFullNodeQuery,
    useGetNodeQuery,
    useGetVersionQuery,
    useGetTagsQuery,
    useGetDocumentSourcesQuery,
    useGetContentTypesQuery,
    useAddSourceToDocumentMutation,
    useGenerateCodebasesMutation,
    useConnectGitlabReposMutation,
    useAddContentToCollectionMutation,
    useRemoveSourceFromDocumentMutation,
    useCreateTagMutation,
    useAddTagToContentMutation,
    useUpdateTagMutation,
    useDeleteDocumentMutation,
    useGetContentTagsQuery,
    useGetTagContentsQuery,
    useRemoveContentFromCollectionMutation,
    usePostAgentPipelineSyncMutation,
    usePostAgentPipelineAsyncMutation,
    usePostCallIdResultsMutation,
    useLazyGetAgentCallStatusQuery,
    useRemoveTagFromContentMutation,
    useDeleteTagMutation,
    useCreateDocumentMutation,
    useUpdateContentMutation,
    useGetContentQuery,
    useLazyGetContentQuery,
    useUploadCodebaseMutation,
    useUploadPdfMutation,
    useGetContentDownloadUrlQuery,
    useGetCodebaseVersionsQuery,
    useGetOrganizationUsersQuery,
    useCreateOrganizationInvitationsMutation,
    useGetUserOrganizationsQuery,
    useGetContentByNodeIdQuery,
    useGetOrganizationInvitationsQuery,
    useRemoveUserFromOrganizationMutation,
    useDeleteInvitationMutation,
    useUpdateOrganizationMutation,
    useUpdateUserRolesMutation,
    useResetPasswordMutation,
    useGetUserInfoQuery,
    useGetUsageBalanceQuery,
    useGetSubscriptionQuery,
    useGetUsageSummaryQuery,
    useGetChargesQuery,
    useLazyGetCodebaseReportQuery,
    useGetCodebaseReportQuery,
    useAnalyzeCodebaseMutation,
    useCreateGitRepoMutation,
    useGetOrganizationRolesQuery,
    useOnboardCodebaseMutation,
    useLazyGetGitlabLoginUrlQuery,
    useCreateTemplateMutation,
    useLazyGetTagContentsQuery,
    useGetGitProviderAppsQuery,
    useGetGitProviderAppReposQuery,
    useCreateGitProviderAppRepoMutation,
    useGetGitProviderAppInstallsQuery,
    useConnectGitProviderAppReposMutation,
    useDeleteGitProviderAppInstallMutation,
    useSaveGitProviderAccessTokenMutation,
    useCreateGitProviderAppMutation,
    useDeleteGitProviderAppMutation,
} = driverApi
